<template>
  <div class="accountingConfigEdit">
    <template v-if="accountingConfig">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            Accounting Config
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t("Customer") }}</label>
          <input
            v-model="accountingConfig.accountingCustomer"
            class="form-control"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t("value") }}</label>
          <input
            v-model="accountingConfig.accountingIssueKey"
            class="form-control"
            type="text"
          >
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="
            !accountingConfig.accountingCustomer ||
              !accountingConfig.accountingIssueKey
          "
          @click.prevent="updateAcountingConfig()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t("save") }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from "@/mixins/errorMixin.js";

export default {
  name: "AccountingConfigEdit",
  mixins: [errorMixin],
  props: {
    accountingConfig: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateAcountingConfig() {
      this.axios
        .put("/OnCallDuty/UpdateAccountingConfig", this.accountingConfig)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(
            this.$t("accounting.configUpdatedSuccessfully")
          );
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
  },
};
</script>
