<template>
  <div class="accountingConfigAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Accounting Config
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t("Customer") }}</label>
        <input
          v-model="accountingConfig.accountingCustomer"
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t("value") }}</label>
        <input
          v-model="accountingConfig.accountingIssueKey"
          class="form-control"
          type="text"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="
          !accountingConfig.accountingCustomer ||
            !accountingConfig.accountingIssueKey
        "
        @click.prevent="addAcountingConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t("add") }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from "@/mixins/errorMixin.js";

export default {
  name: "AccountingConfigAdd",
  mixins: [errorMixin],
  data() {
    return {
      accountingConfig: {},
    };
  },
  methods: {
    async addAcountingConfig() {
      await this.axios
        .post("/OnCallDuty/CreateAccountingConfig", this.accountingConfig)
        .then(() => {
          this.$snotify.success(
            this.$t("accounting.configAddedSuccessfully")
          );
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
  },
};
</script>
