<template>
  <div class="accountingConfigList">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{ height: 'auto' }"
        :data-items="kgm_computedGridItems(accountingConfigs)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :filter="kgm_filter"
        :take="kgm_take"
        :total="kgm_allGridItems(accountingConfigs)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode,
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="jiraUrlTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <a
              :href="jiraHref(props.dataItem.accountingIssueKey)"
              target="_blank"
              class="alt-primary-color"
            >
              {{ props.dataItem.accountingIssueKey }}
            </a>
          </td>
        </template>
        <div
          slot="textFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="enter value"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('textFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <AccountingConfigAdd
          v-if="addingElement"
          @reload="reloadAccountingConfigs"
        />
        <AccountingConfigEdit
          v-else
          :accounting-config="accountingConfig"
          @reload="reloadAccountingConfigs"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="accountingConfig">
        {{ $t("sureToDelete", { "0": accountingConfig.accountingCustomer }) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeAccountingConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t("delete") }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import Sidebar from "@/components/Base/Sidebar.vue";
import AccountingConfigAdd from "@/components/Accounting/AccountingConfigAdd";
import AccountingConfigEdit from "@/components/Accounting/AccountingConfigEdit";
import { mapGetters } from "vuex";
import { urlParseMixin } from "@/mixins/urlParseMixin";

export default {
  name: "AccountingConfigList",
  components: {
    SweetModal,
    Sidebar,
    AccountingConfigAdd,
    AccountingConfigEdit,
  },
  mixins: [kendoGridMixin, urlParseMixin],
  data() {
    return {
      loading: true,
      accountingConfig: null,
      accountingConfigs: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      deleteIndex: null,
      kgm_sort: [
        {
          field: "accountingCustomer",
          dir: "asc",
        },
      ],
      kgm_columns: [
        {
          field: "accountingCustomer",
          filterable: true,
          filter: "text",
          title: this.$t("customer"),
          filterCell: "textFilter",
        },
        {
          field: "accountingIssueKey",
          filterable: true,
          filter: "text",
          title: "Issue",
          filterCell: "textFilter",
          cell: "jiraUrlTemplate",
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplate",
          width: 200,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
  },
  metaInfo() {
    return {
      title: this.$t("accounting.accounting"),
    };
  },
  created() {
    this.loadAccountingConfigs();
  },
  methods: {
    //sidebar
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${id}`);
    },
    show() {
      this.showSidebar = true;
    },
    close() {
      this.showSidebar = false;
    },
    showSidebarAdd() {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit(emitValues) {
      this.setCurrentAccountingConfig(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentAccountingConfig(setting) {
      this.accountingConfig = Object.assign({}, setting);
    },
    //end sidebar

    //api
    loadAccountingConfigs() {
      this.loading = true;
      this.getAccountingConfigs();
    },
    reloadAccountingConfigs() {
      this.showSidebar = false;
      this.getAccountingConfigs();
    },
    openConfirmModal(data) {
      this.setCurrentAccountingConfig(data);
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    removeAccountingConfig() {
      this.axios
        .delete(`/OnCallDuty/DeleteAccountingConfig?id=${this.deleteIndex}`)
        .then(() => {
          this.$snotify.success(
            this.$t("accounting.configDeletedSuccessfully")
          );
          this.reloadAccountingConfigs();
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getAccountingConfigs() {
      this.axios
        .get("/OnCallDuty/GetAccountingConfigs")
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.accountingConfigs = response.data;
          this.$emit("setAccountingConfigs", this.accountingConfigs);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.accountingConfigList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
